import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import types from 'helpers/constants';

export const TranslatableObjectKey = '@ContentTranslation';

export const useTranslatableSelectedLanguage = () => {
  return useSelector(
    root => root.Translatable.selectedLanguage ?? 'pl',
    shallowEqual
  );
};

export const useTranslatableLanguageHandler = () => {
  const dispatch = useDispatch();

  return (e, obj) => {
    dispatch({
      type: types.TRANSLATABLE_SELECT_LANGUAGE,
      payload: {
        selectedLanguage: obj.lang,
      },
    });
  };
};

export const useTranslatableLanguages = () => {
  return useSelector(root => {
    const stateLanguages = root.Auth.languages ?? [];

    return (
      stateLanguages.length === 0 ? [{ isoCode: 'pl' }] : stateLanguages
    ).map(el => el.isoCode);
  }, shallowEqual);
};

export const compareTranslatableValues = (val1, val2) => {
  return JSON.stringify(val1) === JSON.stringify(val2);
};

export const isTranslatableLangIdenticalInTwoObjects = (val1, val2, lang) => {
  return (
    (val1?.[TranslatableObjectKey]?.[lang] ?? null) ===
    (val2?.[TranslatableObjectKey]?.[lang] ?? null)
  );
};

export const isTranslatableValuesObjectEmpty = value => {
  if (!value) {
    return true;
  }

  const translations = Object.keys(value[TranslatableObjectKey] || {});

  return (
    translations.filter(el => !translations[el]).length === translations.length
  );
};

export const isTranslatableLangEmpty = (value, lang) => {
  if (!value) {
    return true;
  }

  return !value[TranslatableObjectKey]?.[lang];
};

export const useGetCompanyDefaultLanguage = () => {
  return useSelector(root => root.Company?.language ?? 'pl');
};

export default useTranslatableSelectedLanguage;
